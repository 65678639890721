<template>
  <div class= "grid p-fluid mt-3">
  <div class="container mt-2 mb-2" v-if="products.length > 0">
    <div class="row">
      <div class="seeopt">
        <!-- <div class="seeall"><a href="#">See All</a></div> -->

        <!-- <div class="arrowicon">
          <i class="fa-solid fa-chevron-left mr-4"></i> &nbsp;
          <i class="fa-solid fa-chevron-right"></i>
        </div> -->
      </div>


        <div class="col-xs-2 col-sm-2 col-md-12 col-lg-11 col-xl-12">
          <Carousel
            :value="products"
            :numVisible="2"
            :numScroll="2"
            :circular="false"
            :responsiveOptions="carouselResponsiveOptions"
          >
            <template #item="slotProps">
              <div class="col-md-12 ">
                <div class="card1 mb-3">
                  <div class="col-6">
                        <Badge v-if=" slotProps.data.discount && slotProps.data.discount > 0" style="margin-top: -10px;color: black;" :value="slotProps.data.discount + '% off'" severity="warning"></Badge>

                      </div>
                  <div class="row">
                    <div class="col-md-4">
                      <!-- <div class="badge3 text-bg-warning">
                        {{ slotProps.data.discount }}% off
                      </div> -->
                      <img
                        :src="slotProps.data.image"
                        class="img-fluid rounded-start img-dis"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6 col-lg-8">
                      <div class="card-body disc-card">
                        <h5 class="card-title" style="font-weight: Bold">
                          {{ FormatService.FormatTitle(slotProps.data.title,40) }}...
                        </h5>
                        <p class="price card-text mt-3">
                          <span
                            v-if="
                              slotProps.data.actual_price <
                                slotProps.data.price ||
                              slotProps.data.actual_price > slotProps.data.price
                            "
                          >
                            <b>
                              ₹
                              {{
                                FormatService.FormatAmount(slotProps.data.price)
                              }}</b
                            >
                            <s style="font-weight: 100; font-size: 12px">
                              ₹
                              {{
                                FormatService.FormatAmount(
                                  slotProps.data.actual_price
                                )
                              }}</s
                            ></span
                          >
                          <b v-else>
                            ₹
                            {{
                              FormatService.FormatAmount(slotProps.data.price)
                            }}</b
                          >
                        </p>

                        <!-- <div class="timer1">123</div>
                  :
                  <div class="timer1">42</div>
                  :
                  <div class="timer1">00</div>
                  :
                  <div class="timer1">08</div> -->
                        <countdown
                          :time="getTime(slotProps.data.date)"
                          format="DD HH:mm:ss"
                        >
                          <template #="{ resolved }">
                            <div id="timer">
                              <div class="timer1">{{ resolved.DD }}</div>
                              :
                              <div class="timer1">{{ resolved.HH }}</div>
                              :
                              <div class="timer1">{{ resolved.mm }}</div>
                              :
                              <div class="timer1">{{ resolved.ss }}</div>
                            </div>
                          </template>
                        </countdown>

                        <button type="button" class="btn-thum" @click="gotoDetails(slotProps.data)">VIEW DETAILS</button>
                      </div>
                    </div>
                  </div>
                </div>
                    
                  
                
                
              </div>
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from "@/apis";
import Countdown from "vue3-countdown";
import FormatService from "../service/FormatService";

export default {
  data() {
    return {
      products: [],

      time: "",
      FormatService: null,

      ResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "960px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      carouselResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  components: { Countdown },
  async created() {
    this.FormatService = new FormatService();
  },
  methods: {
    gotoDetails(data){
      //this.$router.push({name:"pphprodetails"});
      this.$router.push({name:"pphprodetails", params: { id: data._id , title:data.title }});
    },
    GetTodaysDealList() {
            var data = { count: false,page_no:1,limit:10 };
            var promise = apis.GetTodaysDeal(data);
            promise.then((response) => {
                this.products = response.data.data;

                

              

             
            });
        },
        getTime(date)
        {
          const now = new Date();
          const newYear = new Date(date);
          return  newYear - now;
         
        },
        convertstring(data) {
      let words = data.split(" ");
      return words.slice(0, 4).join(" ");
    },
        
    
  },

  mounted() {
    this.GetTodaysDealList();
  },
};
</script>

<style scoped>
.card {
  padding: 0 !important;
}

.btn-thum {
  background-color: #0F75BC;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.img-dis {
  width: 100%;
  /* height: 247px; */
}

#timer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
#timer .timer1 {
  margin: 0.75rem;
    background-color: #eee;
    padding: 0.5rem;
    border-radius: 5px;
}
.seeopt {
  display: flex;
  justify-content: end;
  margin-right: 2px;
  padding-bottom: 10px;
  /* margin-top: 5rem; */
}

.arrowicon {
  display: flex;
  justify-content: space-evenly;
  margin-right: 1rem;
}

.seeall {
  margin-right: 12px;
  align-content: center;
  font-weight: bold;
}

.card1 {
  border: none;
  padding: 1rem;
  /* width:600px */
}

.card1 .img-dis {
  padding-right: 1rem;
}

.badge3 {
  z-index: 99999;
  display: inline-block;
  background-color: #ff8551;
  padding: 4px;
  position: fixed;
  align-items: start;
  /* text-align: start; */
  top: 0;
  width: 50px;
  height: 20px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.75rem;
  margin: 15px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .img-dis {
    width: 250px;
  }
  /* .btn-thum {
    width: 200px;
  } */
  #timer {
  
    width:239px;

  }
 

 
  .btn-thum{
    width:100%;
  }

}
@media screen and (max-width: 600px){
  #timer{
    justify-content: center;
    
  }
  .price{
    text-align: center;
  }

 .card-title{
    text-align: center;
    
  }
}

/* @media only screen and (max-width : 380px){
  .p-carousel-container {
    width: 396px !important;
}
.p-carousel-content {
    width: 395px!important;
}
} */

/* @media screen and (min-width: 768px){
  .card1{
  padding-left:132px;
}

} */



/* @media only screen and (max-width: 1024) {
  .card1 {
  width: 200px;
  padding-left:20px
  }
} */

/* @media only screen and(max-width:400px){
  card
} */
</style>
