<template>
  <div class="grid p-fluid mt-3">
    <Loader v-if="loading" />
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="flex-grow-1 header_cat "><h1 style="font-size: 25px; font-weight: bold">Shop By Categories </h1></div>
          <div
            ref="scrollContainer"
            @mousedown="startScroll"
            @touchstart="startScroll"
            @mouseup="stopScroll"
            @touchend="stopScroll"
            class="categories mt-5"
          >
            <ul class="cat1">
              <li
                v-for="(category, index) in categories"
                :key="index"
                :style="{
                  backgroundColor: activeIndex === index ? '#0F75BC' : '#efefef',
                  color: activeIndex === index ? '#fff' : '#000',
                }"
                @click="toggleActive(index, category)"
                class="categ"
              >
                <a style="font-size: 13px; text-transform: uppercase">{{
                  category
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="product.length > 0">
      <div class="row">
        <Carousel
          :value="product"
          :numVisible="7"
          :numScroll="1"
          :responsiveOptions="carouselResponsiveOptions"
          circular
          
        >
          <template #item="{ data: pphShopByBrand }">
            <div class="product-item1" @click="gotoDetails(pphShopByBrand)">
              <div class="col-6">
                <Badge v-if="pphShopByBrand.discount && pphShopByBrand.discount > 0" style="margin-top: -10px;color: black;" :value="pphShopByBrand.discount+'% off'" severity="warning"></Badge>
                <span v-else><br></span>
              </div>
              
              <div class="cat-img">
                <!-- <div
                class="badge text-bg-warning"
                v-if="pphShopByBrand.discount && pphShopByBrand.discount > 0"
              >
                {{ pphShopByBrand.discount }}% off
              </div> -->
              
                <img
                  :src="pphShopByBrand.image"
                  :alt="pphShopByBrand.image"
                  class="product-image1"
                  v-badge.danger="'10+'"
                />
              </div>
              <h6 class="ml-2">
                {{ FormatService.FormatTitle(pphShopByBrand.title, 15) }}...
              </h6>
              <Rating
                class="rating"
                v-model="pphShopByBrand.total_ratings"
                readonly
                :cancel="false"
              />
              <h5 class="ml-2">
                <span
                  v-if="
                    pphShopByBrand.actual_price < pphShopByBrand.price ||
                    pphShopByBrand.actual_price > pphShopByBrand.price
                  "
                >
                  <b>
                    ₹ {{ FormatService.FormatAmount(pphShopByBrand.price) }}</b
                  >
                  <s style="font-weight: 100; font-size: 12px">
                    ₹
                    {{
                      FormatService.FormatAmount(pphShopByBrand.actual_price)
                    }}</s
                  ></span
                >
                <b v-else>
                  ₹ {{ FormatService.FormatAmount(pphShopByBrand.price) }}</b
                >
              </h5>
              <p class="custname ml-2">
                          by
                          <span
                            class="text-primary"
                            v-if="pphShopByBrand.site_master.length > 0"
                            >{{
                              pphShopByBrand.site_master[0]["name"]
                            }}</span
                          >
                        </p>

              <!-- <p class="ml-2 text-start">
                by<span class="text-primary">{{
                  pphShopByBrand.name
                }}</span>
              </p> -->
            </div>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import apis from "@/apis";
import Loader from "../components/Loader.vue";
import FormatService from "../service/FormatService";
export default {
  components: { Loader },
  data() {
    return {
      loading: false,
      rating: null,
      FormatService: null,
      activeIndex: null,
      images: [],
      carouselResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
      pair_product: [],
      categories: [],
      product: [],
      //product_type: "",
    };
  },
  toggle: (event) => {
    menu.value.toggle(event);
  },
  methods: {
    toggleActive(index, category) 
    {
      this.activeIndex = index;
      this.product=[];
      this.GetProduct(category);
    },
    startScroll(event) {
      const scrollContainer = this.$refs.scrollContainer;
      const startX = event.clientX || event.touches[0].clientX;
      let startScrollLeft = scrollContainer.scrollLeft;
      const scrollHandler = (e) => {
        const x = e.clientX || e.touches[0].clientX;
        const deltaX = startX - x;
        scrollContainer.scrollLeft = startScrollLeft + deltaX;
      };
      const stopHandler = () => {
        document.removeEventListener("mousemove", scrollHandler);
        document.removeEventListener("touchmove", scrollHandler);
        document.removeEventListener("mouseup", stopHandler);
        document.removeEventListener("touchend", stopHandler);
      };
      document.addEventListener("mousemove", scrollHandler);
      document.addEventListener("touchmove", scrollHandler);
      document.addEventListener("mouseup", stopHandler);
      document.addEventListener("touchend", stopHandler);
    },
    stopScroll() {
      // No action needed, scrolling will stop when the mouse/touch is released
    },
    gotoDetails(data) {
      this.$router.push({ name: "pphprodetails", params: { id: data._id , title: data.title} });
    },
    async GetProduct(data) {
      this.product = [];
      if (data) {
       
        var product_type_array=[];
        product_type_array.push(data.toUpperCase());
        //this.product_type = data;
      }
      
      var data = {
        count: false,
        // product_type_array: this.product_type,
        product_type_array: product_type_array,
        limit: 10,
        page_no: 1,
        sort:1
      };
      
      this.loading = true;
      var promise = apis.GetProductList(data);
      promise.then((response) => {
        this.loading = false;
        this.product = response.data.data;
        //this.product_type = "";
      });
    },
    convertstring(data) {
      let words = data.split(" ");
      return words.slice(0, 4).join(" ");
    },
    async GetCategories() {
      var data = { count: false, type: "Component" };
      var promise = apis.GetCategoriesList(data);
      promise.then((response) => {
        this.categories = response.data.data;
        this.GetProduct(this.categories[0]);
      });
    },
  },
  async created() {
    this.FormatService = new FormatService();
    await this.GetCategories();
    
  },
};
</script>
<style scoped>
.product-item1 {
  /* border: 1px solid var(--surface-d); */
  border-radius: 10px;
  margin: 0.3rem;
  text-align: left;
  padding: 2rem 0 3rem 0;
  height: 86%;
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  justify-content: space-evenly;
}
.product-item1:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
}
.product-image1 {
  width: 100px !important;
  height: 100px !important;
 
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}
/* .cat_menu li {
  list-style-type: none;
} */
.header_cat {
  /* display: flex; */
  font-size: 20px;
  font-weight: bold;
  color: #0F75BC;
  /* justify-content: space-between; */
}
/* .header_cat li {
  list-style-type: none;
} */
/* .categories  {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
  list-style-type: none;
} */
.categories {
  list-style: none;
  cursor: pointer;
  align-items: center;
  overflow-x: hidden;
}
a {
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
}
.categories ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}
/* .categories::webkit-scrollbar {
width:0;
}
 */
.categories li {
  white-space: nowrap;
}
.categ {
  display: inline-block; /* Displays list items horizontally */
  margin-right: 10px;
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
}
.categ:hover {
  color: #fff;
  font-weight: bold;
  background-color: #0F75BC;
}
.cat-img {
  /* background-color: #eee; */
  border-radius: 10px;
  text-align: center;
}
.title h6 {
  font-size: 13px;
  padding: 5px;
}
.price {
  padding: 5px;
}
.rating {
  color: #ff8551 !important;
}
.p-rating .p-rating-icon {
  color: #ff8551 !important;
}
.badge {
  z-index: 99999;
  display: inline-block;
  background-color: #ff8551;
  padding-top: 5px;
  /* position: fixed; */
  align-items: start;
  /* text-align: start; */
  top: 0;
  width: 50px;
  height: 20px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.75rem;
  margin: 15px 5px 5px 5px;
}
.dropmenu ul {
  display: none;
}
.categories1 {
  display: none;
  text-align: right;
  list-style: none;
}
.button1 {
  text-align: right;
}
.dropdown {
  display: none;
}
h6.product-image1 {
  text-align: left;
}
@media screen and (max-width: 600px) {
  .dropmenu select {
    display: inline block;
  }
  .categories1 {
    display: inline-block;
    /* align-content: end; */
    z-index: 1;
    position: relative;
    /* text-align: right; */
    /* padding-left: 63%; */
    background-color: antiquewhite;
    align-items: flex-end;
    top: 0;
  }
  .drop1 {
    display: none;
  }
  ul.drop1 {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .categories1 ul {
    list-style: none;
  }
  .categories {
    display: block;
  }
  .product-item1 {
    /* border: 1px solid var(--surface-d); */
    text-align: center;
  }
  .product-item1 h5 {
    text-align: center;
  }
  .p-rating.p-readonly {
    justify-content: center;
  }
}
</style>
