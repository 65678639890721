<template>
    <div class="grid p-fluid mt-3">
        <div class="container">
            <div class="d-flex mt-4">
                <div class="flex-grow-1 header_cat"><h1 style="font-size: 25px; font-weight: bold">Shop By Brands</h1></div>
                <div @click="Goto">
                    <b style="cursor: pointer;color: #0F75BC;">See All</b>
                    
                </div>
            </div>
            <Carousel :value="products" :numVisible="6" :numScroll="6" :circular="false"
                :responsiveOptions="carouselResponsiveOptions">
                <template #item="pphShopByBrand">
                    <div class="product-item1" @click="GoToListWithFilter(pphShopByBrand.data)">
                        <div class="product-item-content1">
                            <div class="mb-3">
                                <img :src="pphShopByBrand.data.url" :alt="pphShopByBrand.data.name"
                                    class="product-image1" />
                            </div>
                            <div></div>
                        </div>
                    </div>
                </template>
            </Carousel>
        </div>
    </div>
</template>
<script>
import apis from "@/apis";
import Carousel from 'primevue/carousel';
export default {
    data() {
        return {
            filters: {
                brand: [],
               
            },
            products: [],
            images: [],
            galleriaResponsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 5,
                },
                {
                    breakpoint: "960px",
                    numVisible: 4,
                },
                {
                    breakpoint: "768px",
                    numVisible: 3,
                },
                {
                    breakpoint: "560px",
                    numVisible: 1,
                },
            ],
            carouselResponsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 3,
                    numScroll: 3,
                },
                {
                    breakpoint: "768px",
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: "560px",
                    numVisible: 1,
                    numScroll: 1,
                },
            ],
        };
    },
    methods: {
        GoToListWithFilter(data)
        { 
        this.filters.brand.push(data.name);
        this.$router.push({ name:"pph_listing", 
                            query: { filters: JSON.stringify(this.filters) }
                            
                            });
        },
        Goto()
        {  
            this.$router.push({name:"pphbrand"});
        },
        GetShopBrands() {
            var data = { count: false };
            var promise = apis.GetBrandsList(data);
            promise.then((response) => {
                //console.log("GetShopBrands",response.data.data);
                this.products = response.data.data;
            });
        },
    },
    async created() { },
    async mounted() {
        this.GetShopBrands();
    },
};
</script>
<style scoped>
.product-item1 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 1px solid var(--surface-d);
    border-radius: 10px;
    margin: 0.3rem;
    text-align: center;
    padding: 2rem 0;
    height: 82%;
    display: flex;
    flex-flow: column nowrap;
    background-color: #fff;
    justify-content: center;
}

.product-image1 {
    width: 50%;
}

.header_cat {
    font-size: 20px;
    font-weight: bold;
    color: #0F75BC;
}


@media screen and (max-width:600px)  {
    .product-image1{
    width: 70%;
}
}
</style>
