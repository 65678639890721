<template >
    <div class="grid p-fluid mt-3">
  <div  class="container">
    <div class="row" >
    
      <div class=" col-xl-3 col-lg-4 col-md-4 col-sm-6" v-for="(data, index) in products" :key="index">
        <div class="card" style=" border:none"    @click="GoToListWithFilter( data.product_type,data.type  )">
            <img  :src="data.url"  alt="" class="image-opt" />
         <div class="card-body">
            <h5 class="card-title text-center pt-2"> {{ data.name }}</h5>
          </div> 
        </div>
      </div>
      </div>
    
    </div>
  </div>

</template>

<script>
import apis from "@/apis";
export default {
  data() {
    return {
      
      products: [],
  
      images: [],
      galleriaResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "960px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      carouselResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },

  methods: {
    GoToListWithFilter(product_type,type)
        {   
            if (type.length > 0 && product_type.length > 0) 
            {
                this.$emit("onFilter", { type:type, product_type:product_type });
            } else if (type.length > 0) {
                this.$emit("onFilter", { type:type });
            } else if (product_type.length > 0) {
                this.$emit("onFilter", { product_type:product_type });
            } else {
                this.$router.push({ name: '/listing' });
            }

        },
        GetColleactionCart() {
            var data = { count: false };
            var promise = apis.GetCollection(data);
            promise.then((response) => {
                //console.log("GetColleactionCart",response.data.data);
                this.products = response.data.data;
            });
        },
    },

     
    mounted() {
    this.GetColleactionCart();
  },

};
</script>

<style scoped>
.card-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.image-opt {
  background-color: #feffb6;
  border-radius: 10px;
}
.collaction{
  display: flex;
  flex-direction: row;

}


.card{
  box-shadow: none !important;
}

</style>
