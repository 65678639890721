<template>
    <div class="adbg">
        <!-- <img :src=ad v-if="ad"> -->
       
    <Adsense
            style="width: 100%; min-width: 250px"
            ins-style="display:inline-block;width:728px;height:90px"
            data-ad-client="ca-pub-1812041019524792"
            data-ad-slot="123456789">
    </Adsense>

    <!-- <amp-auto-ads type="adsense"
        data-ad-client="ca-pub-1812041019524792">
    </amp-auto-ads> -->
    
    <!-- <hr>
    <InArticleAdsense
    data-ad-client="ca-pub-1812041019524792"
    data-ad-slot="1234567890">
    </InArticleAdsense>
    <hr>
    <InFeedAdsense
    data-ad-layout-key="-fg+5n+6t-e7+r"
    data-ad-client="ca-pub-1812041019524792"
    data-ad-slot="1234567890">
    </InFeedAdsense> -->
    </div>
</template>

<script>
import apis from "@/apis";
import axios from "axios";
export default {
    data() {
        return {
            products: [],
            ad:"",
        };
    },
    methods: {
        GetAdsBanner() {
            var data = { count: false };
            var promise = apis.GetAdsBannerList(data);
            promise.then((response) => {
                //console.log("GetAdsBanner",response.data.data);
                this.products = response.data.data;
                this.ad=this.products[0]['image_url'];
            });
        },
    },
    async created() {this.GetAdsBanner(); },
    async mounted() {
        
    },
}
</script>

<style scoped>
.adbg img{
    width:100%;
}
</style>
