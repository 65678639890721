<template>
    <div class="grid p-fluid mt-3">
        <div class="container">
            <Carousel :value="products" :numVisible="6" :numScroll="6" :circular="true"
                :responsiveOptions="carouselResponsiveOptions">
                <template #item="pphproduct">
                    <div class="product-item1" @click="GoToListWithFilter(pphproduct.data.product_type,pphproduct.data.type, pphproduct.data.url)">
                        <div class="product-item-content1">
                            <div class="mb-3 ">
                                <img :src="pphproduct.data.image_url" :alt="pphproduct.data.name" class="product-image1" />
                            </div>
                            <div>
                                <h4 class="mb-1 title">
                                    {{ pphproduct.data.name }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </template>
            </Carousel>
        </div>
    </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
export default {
    data() {
        return {
            
            products: [],
            images: [],
            galleriaResponsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 5,
                },
                {
                    breakpoint: "960px",
                    numVisible: 4,
                },
                {
                    breakpoint: "768px",
                    numVisible: 3,
                },
                {
                    breakpoint: "560px",
                    numVisible: 1,
                },
            ],
            carouselResponsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 3,
                    numScroll: 3,
                },
                {
                    breakpoint: "768px",
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: "560px",
                    numVisible: 1,
                    numScroll: 1,
                },
            ],
        };
    },
    methods: {
        GoToListWithFilter(product_type,type,url)
        {   
            if (type.length > 0 && product_type.length > 0) 
            {
                this.$emit("onFilter", { type:type, product_type:product_type });
            } else if (type.length > 0) {
                this.$emit("onFilter", { type:type });
            } else if (product_type.length > 0) {
                this.$emit("onFilter", { product_type:product_type });
            } else {
                this.$router.push({ name: url });
            }
            
        },
        GetScrollCart() {
            var data = { count: false };
            var promise = apis.GetProductTypesList(data);
            promise.then((response) => {
                //console.log("GetScrollCart",response.data.data);
                this.products = response.data.data;
            });
        },
    },
    async created() { },
    async mounted() {
        this.GetScrollCart();
    },
};
</script>
<style scoped>
.product-item1 {
    border: 1px solid var(--surface-d);
    border-radius: 10px;
    margin: 0.3rem;
    text-align: center;
    padding: 2rem 0;
    display: flex;
    justify-content: space-evenly;
    background-color: #fff;
}

.product-image1 {
    width: 50%;
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

/* 
    .product-item-content1 {
        border: 1px solid var(--surface-d);
        border-radius: 3px;
        margin: 0.3rem;
        text-align: center;
        padding: 2rem 0;
    } */
.product-item1:hover {
    background-color: #deecf7;
}

.title {
    font-size: 16px;
}

@media only screen and (max-width: 400px) {
    form-control {
        width: 100px;
    }

   
}




</style>