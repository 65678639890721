<template>
  <div class="container-fluid mt-4">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <!-- <ol class="carousel-indicators">
        <li v-for="(item, index) in products" :key="index" :data-target="carouselTarget" :data-slide-to="index"
          :class="{ active: index === currentIndex }"></li>
      </ol> -->
      <div class="carousel-inner">
        <div v-for="(item, index) in products" :key="index"
          :class="{ 'carousel-item': true, 'active': index === currentIndex }">
          <img class=" banner1 " :src="item.link" :alt="item.name" >
        </div>
      </div>
      <a class="carousel-control-prev" :href="carouselTarget" role="button" @click.prevent="prevSlide">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" :href="carouselTarget" role="button" @click.prevent="nextSlide">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <!-- <div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="/bg1.png" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item active">
      <img src="/bg1.png" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item active">
      <img src="/bg1.png" class="d-block w-100" alt="...">
    </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div> -->
    <!-- <Carousel :value="products" :numVisible="1" :numScroll="1" orientation="horizontal" verticalViewPortHeight="330px" contentClass="flex align-items-center">
            <template #item="slotProps">{{ slotProps.data.link }}
              <img :src="slotProps.data.link" class="d-block w-100" alt="...">
            </template>
  </Carousel> -->
  </div>
</template>
<script>
import apis from "@/apis";
export default {
  name: 'PPHTest',
  data() {
    return {
      currentIndex: 0,
      products: [],
      carouselTarget: "#carouselExampleIndicators",
    };
  },
  mounted() {
    this.GetBanner();
  },
  methods: {
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.products.length) % this.products.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.products.length;
    },
    GetBanner() {
      var data = { count: false };
      var promise = apis.GetBannerList(data);
      promise.then((response) => {
        //console.log("GetBannerList",response.data.data);
        this.products = response.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>


.banner1{
  display: block;
  width:100%;
  height:400px;
}

@media screen and (max-width: 600px) {
  .banner1{
    height: 90px;
  }
  
}

@media screen and (min-width: 768px) {
  .banner1{
    height: 210px;
  }
  
}

@media screen and (min-width: 1028px) {
  .banner1{
    height: 350px;
  }
  
}




</style>