<template>
  <div class="grid p-fluid mt-3">
    <Loader v-if="loading" />
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div
            ref="scrollContainer"
            @mousedown="startScroll"
            @touchstart="startScroll"
            @mouseup="stopScroll"
            @touchend="stopScroll"
            class="categories mt-5"
          >
            <ul style="font-size: 10px">
              <li
                v-for="(category, index) in categories"
                :key="index"
                :style="{
                  backgroundColor: activeIndex === index ? '#0F75BC' : '#efefef',
                  color: activeIndex === index ? '#fff' : '#000',
                }"
                @click="toggleActive(index , category)"
                class="categ"
              >
                <a
                
                  style="font-size: 13px; text-transform: uppercase"
                  >{{ category }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="container" v-if="product.length > 0">
          <div class="row">
        <Carousel
          :value="product"
          :numVisible="3"
          :numScroll="1"
          circular
          :responsiveOptions="carouselResponsiveOptions"
        >
          <template #item="{ data: pphShopByBrand }">
            <div
              class="container"
              v-if="pphShopByBrand['0']"
              style="margin-top: 20px"
              @click="gotoDetails(pphShopByBrand['0'])"
            >
              <div class="row mr-2">
                <div class="card mb-3" style="max-width: 420px">
                  <div class="row g-0">
                    <div class="col-md-4">
                      <!-- <div class="badge1 text-bg-warning">
                        {{ pphShopByBrand["0"].discount }}% off
                      </div> -->
                      <div class="col-8">
                        <Badge v-if="pphShopByBrand['0'] && pphShopByBrand['0'].discount && pphShopByBrand['0'].discount > 0" style="margin-top: -10px;color: black;" :value="pphShopByBrand['0'].discount + '% off'" severity="warning"></Badge>
                        <span v-else><br></span>
                      </div>
                      <div class="card-img">
                        <img
                          :src="pphShopByBrand['0'].image"
                          class="img-fluid rounded-start"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="card-body details">
                        <h6 class="card-title ml-2">
                          {{
                            FormatService.FormatTitle(
                              pphShopByBrand["0"].title,
                              20
                            )
                          }}...
                        </h6>
                        <Rating
                          class="rating"
                          v-model="pphShopByBrand['0'].total_ratings"
                          readonly
                          :cancel="false"
                        />
                        <h5 class="card-text ml-2">
                          <span
                            v-if="
                              pphShopByBrand['0'].actual_price <
                                pphShopByBrand['0'].price ||
                              pphShopByBrand['0'].actual_price >
                                pphShopByBrand['0'].price
                            "
                          >
                            <b>
                              ₹
                              {{
                                FormatService.FormatAmount(
                                  pphShopByBrand["0"].price
                                )
                              }}</b
                            >
                            <s style="font-weight: 100; font-size: 12px">
                              ₹
                              {{
                                FormatService.FormatAmount(
                                  pphShopByBrand["0"].actual_price
                                )
                              }}</s
                            ></span
                          >
                          <b v-else>
                            ₹
                            {{
                              FormatService.FormatAmount(
                                pphShopByBrand["0"].price
                              )
                            }}</b
                          >
                        </h5>
                        <p class="custname ml-2">
                          by
                          <span
                            class="text-primary"
                            v-if="pphShopByBrand['0'].site_master.length > 0"
                            >{{
                              pphShopByBrand["0"].site_master[0]["name"]
                            }}</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="container"
              v-if="pphShopByBrand['1']"
              @click="gotoDetails(pphShopByBrand['1'])"
            >
              <div class="row mr-2">
                <div class="card mb-3" style="max-width: 420px">
                  <div class="row g-0">
                    <div class="col-md-4 position:relative">
                      <!-- <div class="badge2 text-bg-warning position:absolute">
                        {{ pphShopByBrand["1"].discount }}% off
                      </div> -->
                      <div class="col-8">
                        <Badge v-if="pphShopByBrand['1'] && pphShopByBrand['1'].discount && pphShopByBrand['1'].discount > 0" style="margin-top: -10px;color: black;" :value="pphShopByBrand['1'].discount + '% off'" severity="warning"></Badge>
                        <span v-else><br></span>
                      </div>
                      <div class="card-img">
                        <img
                          :src="pphShopByBrand['1'].image"
                          class="img-fluid rounded-start"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="card-body details1">
                        <h6 class="card-title ml-2">
                          {{
                            FormatService.FormatTitle(
                              pphShopByBrand["1"].title,
                              20
                            )
                          }}...
                        </h6>
                        <Rating
                          class="rating"
                          v-model="pphShopByBrand['1'].total_ratings"
                          readonly
                          :cancel="false"
                        />
                        <h5 class="card-text ml-2">
                          <span
                            v-if="
                              pphShopByBrand['1'].actual_price <
                                pphShopByBrand['1'].price ||
                              pphShopByBrand['1'].actual_price >
                                pphShopByBrand['1'].price
                            "
                          >
                            <b>
                              ₹
                              {{
                                FormatService.FormatAmount(
                                  pphShopByBrand["1"].price
                                )
                              }}</b
                            >
                            <s style="font-weight: 100; font-size: 12px">
                              ₹
                              {{
                                FormatService.FormatAmount(
                                  pphShopByBrand["1"].actual_price
                                )
                              }}</s
                            ></span
                          >
                          <b v-else>
                            ₹
                            {{
                              FormatService.FormatAmount(
                                pphShopByBrand["1"].price
                              )
                            }}</b
                          >
                        </h5>
                        <p class="custname ml-2">
                          by
                          <span
                            class="text-primary"
                            v-if="pphShopByBrand['1'].site_master.length > 0"
                            >{{
                              pphShopByBrand["1"].site_master[0]["name"]
                            }}</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Carousel>
      </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import apis from "@/apis";
import Loader from "../components/Loader.vue";
import FormatService from "../service/FormatService";
export default {
  components: { Loader },
  data() {
    return {
      loading: false,
      rating: null,
      FormatService: null,
      activeIndex: null,
      clicked: false,
      isActive: false,
      activeIndex: null,
      images: [],
      galleriaResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "960px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      carouselResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
      pair_product: [],
      categories: [],
      product: [],
      //product_type: "",
    };
  },
  methods: {
    toggleActive(index,category) {
      this.activeIndex = index;
      this.product=[];
      this.GetProduct(category);
    },
    startScroll(event) {
      const scrollContainer = this.$refs.scrollContainer;
      const startX = event.clientX || event.touches[0].clientX;
      let startScrollLeft = scrollContainer.scrollLeft;
      const scrollHandler = (e) => {
        const x = e.clientX || e.touches[0].clientX;
        const deltaX = startX - x;
        scrollContainer.scrollLeft = startScrollLeft + deltaX;
      };
      const stopHandler = () => {
        document.removeEventListener("mousemove", scrollHandler);
        document.removeEventListener("touchmove", scrollHandler);
        document.removeEventListener("mouseup", stopHandler);
        document.removeEventListener("touchend", stopHandler);
      };
      document.addEventListener("mousemove", scrollHandler);
      document.addEventListener("touchmove", scrollHandler);
      document.addEventListener("mouseup", stopHandler);
      document.addEventListener("touchend", stopHandler);
    },
    stopScroll() {
      // No action needed, scrolling will stop when the mouse/touch is released
    },
    gotoDetails(data) {
      // this.$router.push({name:"pphprodetails"});
      this.$router.push({ name: "pphprodetails", params: { id: data._id , title:data.title } });
    },
    convertstring(data) {
      let words = data.split(" ");
      return words.slice(0, 4).join(" ");
    },
    async GetProduct(data) {
      this.product = [];
      if (data) {
        // this.product_type = data;
        var product_type_array=[];
        product_type_array.push(data.toUpperCase())
      }
      var data = {
        count: false,
        // product_type: this.product_type,
        product_type_array: product_type_array,
        limit: 10,
        page_no: 1,
        sort:1
      };
      this.loading = true;
      var promise = apis.GetProductList(data);
      promise.then((response) => {
        this.loading = false;
        //console.log("GetProduct", response.data.data);
        this.product = response.data.data;
        //this.product_type = "";
        const temp = [];
        for (let index = 0; index < this.product.length; index += 2) {
          temp.push([this.product[index], this.product[index + 1]]);
        }
        this.product = temp;
        // console.log("temp", temp);
      });
    },
    async GetCategories() {
      var data = { count: false, type: "Peripherals" };
      var promise = apis.GetCategoriesList(data);
      promise.then((response) => {
        //console.log("GetCategories", response.data.data);
        this.categories = response.data.data;
        this.GetProduct(this.categories[0]);
      });
    },
  },
  async created() {
    this.FormatService = new FormatService();
    await this.GetCategories();
    
  },
};
</script>
<style scoped>
.active {
  display: inline-block; /* Displays list items horizontally */
  margin-right: 10px;
  background-color: #491212;
  padding: 10px;
  border-radius: 10px;
}
.active li {
  list-style: none;
  display: flex;
  flex-direction: row;
}
.white {
  background-color: white;
}
.blue {
  background-color: blue;
}
.categories {
  list-style: none;
  cursor: pointer;
  align-items: center;
  overflow-x: hidden;
}
a {
  font-weight: 700;
  font-size: 15px;
}
.categories ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}
.categories.cat1 {
  display: inline-block;
  padding: 10px;
}
.categories li {
  white-space: nowrap;
}
.categ {
  display: inline-block; /* Displays list items horizontally */
  margin-right: 10px;
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
}
/* .categ:active {
  color: #fff;
  font-weight: bold;
  background: #0003ac;
} 
a{
  color:#fff;
} */
.card-img {
  background-color: #eee;
}
.badge1 {
  z-index: 99999;
  display: inline-block;
  background-color: #ff8551;
  padding-left: 5px;
  padding-top: 2px;
  position: fixed;
  align-items: start;
  /* text-align: start; */
  top: 0;
  width: 50px;
  height: 20px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 36px;
}
.badge2 {
  z-index: 1;
  display: inline-block;
  background-color: #ff8551;
  padding-left: 5px;
  padding-top: 2px;
  position: fixed;
  top: 0;
  width: 50px;
  height: 20px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.75rem;
}
.card-container {
  position: relative;
  display: inline-block;
}
.popsearch {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card-body .details {
  text-align: left;
}
.card-body .details1 {
  text-align: left;
}
@media (max-width: 600px) {
  .badge2 {
    margin-top: 503px;
    /* margin-left: 100px; */
  }
  .card-body.details {
    text-align: center;
  }
  .card-body.details1 {
    text-align: center;
  }
  .ratingcard {
    justify-content: center;
    color: #ff8551 !important;
  }
  .rating {
  color: #ff8551 !important;
}
.p-rating .p-rating-icon {
  color: #ff8551 !important;
}
.p-rating.p-readonly {
    justify-content: center;
  }
  .ratingcard1 {
    justify-content: center;
    color: #ff8551 !important;
  }
}
@media (max-width: 400px) {
  .badge2 {
    margin-top: 463px;
    /* margin-left: 100px; */
  }
}
@media (min-width: 768px) {
  .badge2 {
    margin-top: 249px;
  }
}
@media (min-width: 1024px) {
  .badge2 {
    margin-top: 251px;
    position: fixed;
  }
}
/* 
  @media screen and (min-width:1440px){
    .badge2{
  margin-top:236px;
} */
</style>
